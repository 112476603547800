.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.logo-placeholder {
    height: 50px;
    width: 247px;
}

.login-text {
    height: 49px;
    width: 101px;
    color: #505D70;
    font-size: 36px;
    letter-spacing: 0;
    line-height: 49px;
}

.login-extra-text {
    height: 21px;
    width: 303px;
    color: #727E8C;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    text-align: center;
}

.label-email {
    height: 21px;
    width: 35px;
    color: #727E8C;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
}

.label-password {
    height: 21px;
    width: 64px;
    color: #727E8C;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
}

.road-to-signup {
    height: 19px;
    width: 231px;
    color: #727E8C;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
    margin-top: 0.5vh;
}

.input-field {
    height: 42px;
    width: 360px;
    border-radius: 12px;
    border: none;
    background-color: #FFFFFF;
    outline: 0;
    color: #727E8C;
    font-size: 14px;
    font-style: italic;
    letter-spacing: 0;
    line-height: 21px;
    padding: 10px;
}

.input-field:focus {
    height: 42px;
    width: 360px;
    border-radius: 12px;
    border: none;
    background-color: #FFFFFF;
    outline: 0;
    color: #727E8C;
    font-size: 14px;
    font-style: italic;
    letter-spacing: 0;
    line-height: 21px;
    padding: 13px;
}

.form-button {}

.input-field::placeholder {
    opacity: 0.6;
    color: #727E8C;
    font-size: 14px;
    font-style: italic;
    letter-spacing: 0;
    line-height: 21px;
    padding: 10px;
}

.input-field::-webkit-input-placeholder {
    opacity: 0.6;
    color: #727E8C;
    font-size: 14px;
    font-style: italic;
    letter-spacing: 0;
    line-height: 21px;
    padding: 10px;
}

.icon-info {
    margin-right: 0.6vh;
    margin-top: 0.2vh;
}

.btn-login {
    height: 36px;
    width: 90px;
    border: 0;
    border-radius: 18px;
    background-color: #596BFF;
    box-shadow: 0 6px 16px 0 rgba(65, 82, 125, 0.16);
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
}

.btn-login:focus {
    outline: 0;
}

.btn-text {
    height: 16px;
    /* width: 35px; */
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
}

.login-details-sign-up {
    color: #596bff;
    font-weight: 600;
    cursor: pointer;
}

.error {
    width: 100%;
    display: block;
    color: red;
    height: 21px;
    opacity: 0.6;
    font-size: 14px;
    /* font-style: italic; */
    letter-spacing: 0;
    line-height: 21px;
    padding: 10px;
}

.login-forgot-password {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #596bff;
    margin-bottom: 12px;
    cursor: pointer;
}